// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";

var initialState = {
  isSubmitting: false,
  error: undefined
};

function reducer(param, action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return initialState;
    } else {
      return {
              isSubmitting: true,
              error: undefined
            };
    }
  } else {
    return {
            isSubmitting: false,
            error: Caml_option.some(action._0)
          };
  }
}

function useFormReducer(param) {
  return React.useReducer(reducer, initialState);
}

export {
  initialState ,
  reducer ,
  useFormReducer ,
}
/* react Not a pure module */
