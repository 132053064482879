// Generated by ReScript, PLEASE EDIT WITH CARE

import AuthContextTsx from "../../contexts/AuthContext.tsx";

function useAuth(prim) {
  return AuthContextTsx();
}

export {
  useAuth ,
}
/* ../../contexts/AuthContext.tsx Not a pure module */
