// Generated by ReScript, PLEASE EDIT WITH CARE

import LoginLazy from "./login-lazy";

var make = LoginLazy;

export {
  make ,
}
/* make Not a pure module */
