
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SeoMeta from "../components/library/SeoMeta.bs.js";
import * as LoginLazy from "../components/pages/login/LoginLazy.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PageContentResolver from "../utils/PageContentResolver.bs.js";
import MasqueradeTsx from "../components/Masquerade.tsx";

var make = MasqueradeTsx;

var Masquerade = {
  make: make
};

function Login$wrapper(props) {
  var loginContent = PageContentResolver.parse(props.content);
  var f_seo_meta = function (v) {
    return JsxRuntime.jsx(SeoMeta.make, {
                meta: v.VAL
              });
  };
  var seo_meta = loginContent !== undefined ? Belt_Option.mapWithDefault(loginContent.seo_meta, null, f_seo_meta) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                seo_meta,
                JsxRuntime.jsx(make, {}),
                JsxRuntime.jsx(LoginLazy.make, {})
              ]
            });
}

var wrapper = Login$wrapper;

var $$default = Login$wrapper;

var getServerSideProps = PageContentResolver.resolver;

export {
  Masquerade ,
  wrapper ,
  $$default ,
  $$default as default,
  getServerSideProps ,
}
/* make Not a pure module */
