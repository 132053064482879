// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../../common/util.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Regex from "../../../utils/Regex.bs.js";
import * as React from "react";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as SmartLink from "../../library/SmartLink.bs.js";
import Link from "next/link";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as FormHelpers from "../../../utils/FormHelpers.bs.js";
import * as FormReducer from "../../../utils/FormReducer.bs.js";
import * as Router from "next/router";
import * as $$Notification from "../../library/Notification.bs.js";
import * as AuthContextTS from "../../../auth/user/AuthContextTS.bs.js";
import * as ReactHookForm from "../../../bindings/ReactHookForm.bs.js";
import * as LayoutDataContext from "../../../utils/LayoutDataContext.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Button$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Button/Button.bs.js";
import * as Kontent$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/Kontent/Kontent.bs.js";
import * as LoginOnboardingStyles from "./LoginOnboardingStyles.bs.js";
import * as KontentContextProvider from "../index/KontentContextProvider.bs.js";
import * as TextField$PrezzeeRibbon from "@prezzee/ribbon/src/components/personal/TextField/TextField.bs.js";

function getCards(wallet) {
  return Belt_Option.mapWithDefault(wallet === null ? undefined : Caml_option.some(wallet), [], (function (w) {
                return w.cards;
              }));
}

function LoginComponent(props) {
  var purchaseCallback = props.purchaseCallback;
  var isPurchase = props.isPurchase;
  var isPurchase$1 = isPurchase !== undefined ? isPurchase : false;
  var layoutData = LayoutDataContext.useContext(undefined);
  var loginData = Belt_Option.flatMap(layoutData, (function (o) {
          return o.login;
        }));
  var match = FormReducer.useFormReducer(undefined);
  var dispatch = match[1];
  var state = match[0];
  var router = Router.useRouter();
  var passwordReset = Js_dict.get(router.query, "passwordReset");
  var giftClaimed = Js_dict.get(router.query, "giftClaimed");
  var login_title = Belt_Option.mapWithDefault(loginData, "Log in to Prezzee", (function (c) {
          return c.title;
        }));
  var login_subtitle = Belt_Option.flatMap(loginData, (function (c) {
          return c.subtitle;
        }));
  var login_emailLabel = Belt_Option.mapWithDefault(loginData, "Email", (function (c) {
          return c.emailLabel;
        }));
  var login_passwordLabel = Belt_Option.mapWithDefault(loginData, "Password", (function (c) {
          return c.passwordLabel;
        }));
  var login_requiredEmailText = Belt_Option.mapWithDefault(loginData, "Your email is required", (function (c) {
          return c.requiredEmailText;
        }));
  var login_requiredPasswordText = Belt_Option.mapWithDefault(loginData, "Your password is required", (function (c) {
          return c.requiredPasswordText;
        }));
  var login_invalidEmailText = Belt_Option.mapWithDefault(loginData, "Please enter a valid email address", (function (c) {
          return c.invalidEmailText;
        }));
  var login_submitCtaText = Belt_Option.mapWithDefault(loginData, "Log in", (function (c) {
          return c.submitCtaText;
        }));
  var login_submitCtaSubmittingText = Belt_Option.mapWithDefault(loginData, "Submitting...", (function (c) {
          return c.submitCtaSubmittingText;
        }));
  var login_forgotPasswordText = Belt_Option.mapWithDefault(loginData, "Forgot your password?", (function (c) {
          return c.forgotPasswordText;
        }));
  var login_forgotEmailText = Belt_Option.mapWithDefault(loginData, "Forgot your email?", (function (c) {
          return c.forgotEmailText;
        }));
  var login_smartLink = Belt_Option.mapWithDefault(loginData, SmartLink.emptySmartLink, (function (c) {
          return c.smartLink;
        }));
  var emailInput = {
    required: {
      value: true,
      message: login_requiredEmailText
    },
    pattern: {
      value: Regex.emailRegex,
      message: login_invalidEmailText
    }
  };
  var passwordInput = {
    required: {
      value: true,
      message: login_requiredPasswordText
    }
  };
  var form = ReactHookForm.useForm({
        mode: "onChange"
      }, undefined);
  var match$1 = form.formState;
  var authCtx = AuthContextTS.useAuth(undefined);
  var handleLogin = function (customerOpt) {
    if (customerOpt === undefined) {
      return ;
    }
    var redirectUrl = Belt_Option.getWithDefault(Js_dict.get(router.query, "redirecturl"), "");
    if (redirectUrl !== "") {
      router.push(redirectUrl);
    } else if (isPurchase$1) {
      Belt_Option.map(purchaseCallback, (function (cb) {
              Curry._1(cb, undefined);
            }));
    } else {
      router.push("/wallet");
    }
  };
  var onSubmit = React.useCallback((function (data) {
          Curry._1(dispatch, /* Submit */0);
          return Js_promise.$$catch((function (e) {
                        Curry._1(dispatch, /* Error */{
                              _0: /* UnexpectedError */0
                            });
                        return Promise.resolve(e);
                      }), Js_promise.then_((function (param) {
                            return Promise.resolve(undefined);
                          }), Curry._3(authCtx.login, data.loginEmail, data.loginPassword, handleLogin)));
        }), [isPurchase$1]);
  var x = login_subtitle;
  return JsxRuntime.jsx(React.Fragment, {
              children: JsxRuntime.jsx(KontentContextProvider.make, {
                    children: JsxRuntime.jsx(Kontent$PrezzeeRibbon.Consumer.make, {
                          children: JsxRuntime.jsxs("div", {
                                children: [
                                  authCtx.error !== null ? JsxRuntime.jsx($$Notification.make, {
                                          notificationType: /* NotificationInfo */1,
                                          title: "Login error",
                                          description: authCtx.error !== null ? authCtx.error.message : "",
                                          className: LoginOnboardingStyles.accountMsg
                                        }, "loginNotification") : null,
                                  JsxRuntime.jsx("h1", {
                                        children: Util.ReactStuff.s(Belt_Option.mapWithDefault(passwordReset, login_title, (function (param) {
                                                    return "Success!";
                                                  }))),
                                        className: LoginOnboardingStyles.title
                                      }),
                                  x !== undefined ? JsxRuntime.jsx("h2", {
                                          children: Util.ReactStuff.s(Belt_Option.mapWithDefault(passwordReset, x, (function (param) {
                                                      return "Sign in with your new details";
                                                    }))),
                                          className: LoginOnboardingStyles.subtitle
                                        }) : null,
                                  Belt_Option.mapWithDefault(giftClaimed, null, (function (query) {
                                          if (query === "true") {
                                            return JsxRuntime.jsx($$Notification.make, {
                                                        notificationType: /* NotificationInfo */1,
                                                        description: "This eGift card has been added to a Prezzee wallet",
                                                        className: LoginOnboardingStyles.accountMsg
                                                      }, "loginNotification");
                                          } else {
                                            return null;
                                          }
                                        })),
                                  JsxRuntime.jsxs("form", {
                                        children: [
                                          JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                                                autoComplete: "email",
                                                className: LoginOnboardingStyles.formInput_,
                                                disabled: state.isSubmitting,
                                                label: login_emailLabel,
                                                name: "loginEmail",
                                                type_: "text",
                                                error: Belt_Option.isSome(FormHelpers.getTouchedError(form, "loginEmail")),
                                                helperText: Belt_Option.getWithDefault(FormHelpers.getTouchedError(form, "loginEmail"), ""),
                                                inputRef: Caml_option.some(Curry._1(form.register, emailInput))
                                              }),
                                          JsxRuntime.jsx(TextField$PrezzeeRibbon.make, {
                                                className: LoginOnboardingStyles.formInput_,
                                                disabled: state.isSubmitting,
                                                label: login_passwordLabel,
                                                name: "loginPassword",
                                                type_: "password",
                                                error: Belt_Option.isSome(FormHelpers.getFieldError(form.errors, "loginPassword")),
                                                helperText: Belt_Option.getWithDefault(FormHelpers.getTouchedError(form, "loginPassword"), ""),
                                                inputRef: Caml_option.some(Curry._1(form.register, passwordInput))
                                              }),
                                          JsxRuntime.jsx(Button$PrezzeeRibbon.make, {
                                                children: JsxRuntime.jsx("button", {
                                                      children: Util.ReactStuff.s(state.isSubmitting ? login_submitCtaSubmittingText : login_submitCtaText),
                                                      className: LoginOnboardingStyles.loginBtn,
                                                      disabled: !match$1.isDirty || !match$1.isValid || state.isSubmitting
                                                    })
                                              })
                                        ],
                                        onSubmit: Curry._2(form.handleSubmit, onSubmit, FormHelpers.onError)
                                      }),
                                  JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx(Link, {
                                                href: "/forgot-password",
                                                prefetch: false,
                                                children: JsxRuntime.jsx("a", {
                                                      children: Util.ReactStuff.s(login_forgotPasswordText),
                                                      className: LoginOnboardingStyles.linkStyle
                                                    })
                                              }),
                                          JsxRuntime.jsx(Link, {
                                                href: "/forgot-email-address",
                                                prefetch: false,
                                                children: JsxRuntime.jsx("a", {
                                                      children: Util.ReactStuff.s(login_forgotEmailText),
                                                      className: LoginOnboardingStyles.linkStyle
                                                    })
                                              })
                                        ],
                                        className: LoginOnboardingStyles.forgotBlock
                                      })
                                ]
                              })
                        }),
                    itemId: login_smartLink.itemId,
                    elementCodename: login_smartLink.codename
                  })
            });
}

var Link$1;

var KontentContextConsumer;

var make = LoginComponent;

export {
  Link$1 as Link,
  KontentContextConsumer ,
  getCards ,
  make ,
}
/* Util Not a pure module */
