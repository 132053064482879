// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../common/util.bs.js";
import * as $$String from "@rescript/std/lib/es6/string.js";
import Link from "next/link";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as StaticImage from "../StaticImage.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function DownloadAppleApp(props) {
  var baseHref = "https://itunes.apple.com/au/app/prezzee/id1047060902";
  var country = Util.EnvUtil.currentCountry;
  var href;
  href = country.TAG === /* Ok */0 ? baseHref.replace("au", $$String.lowercase_ascii(Util.EnvUtil.getCountryCode(country._0))) : baseHref;
  return JsxRuntime.jsx(Link, {
              href: href,
              prefetch: false,
              children: JsxRuntime.jsx("a", {
                    children: JsxRuntime.jsx(StaticImage.make, {
                          src: "/images/badge_apple_store.svg",
                          alt: "Download on the App Store Logo",
                          width: 125,
                          height: 37,
                          className: "w-full ml-1"
                        }),
                    className: Belt_Option.getWithDefault(props.className, "")
                  })
            });
}

var Link$1;

var make = DownloadAppleApp;

export {
  Link$1 as Link,
  make ,
}
/* Util Not a pure module */
