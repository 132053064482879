// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = "rounded-lg px-4 py-5 max-w-3xl text-left mx-auto";

var notificationStyle = "fill-current h-6 w-6 mr-4";

function rootContainer(notificationType) {
  switch (notificationType) {
    case /* NotificationError */0 :
        return Cn.make([
                    container,
                    "bg-error-pink"
                  ]);
    case /* NotificationInfo */1 :
        return Cn.make([
                    container,
                    "bg-info-blue"
                  ]);
    case /* NotificationSuccess */2 :
        return Cn.make([
                    container,
                    "bg-success-green"
                  ]);
    case /* NotificationWarning */3 :
        return Cn.make([
                    container,
                    "bg-warning-orange"
                  ]);
    
  }
}

function icon(notificationType) {
  switch (notificationType) {
    case /* NotificationError */0 :
        return JsxRuntime.jsxs("svg", {
                    children: [
                      JsxRuntime.jsx("mask", {
                            children: JsxRuntime.jsx("path", {
                                  d: "M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM22.3477 20.25L20.25 22.3477L15 17.0977L9.75 22.3477L7.65234 20.25L12.9023 15L7.65234 9.75L9.75 7.65234L15 12.9023L20.25 7.65234L22.3477 9.75L17.0977 15L22.3477 20.25Z",
                                  fill: "white"
                                }),
                            id: "mask0",
                            height: "30",
                            width: "30",
                            maskUnits: "userSpaceOnUse",
                            x: "0",
                            y: "0"
                          }),
                      JsxRuntime.jsx("g", {
                            children: JsxRuntime.jsx("path", {
                                  d: "M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM22.3477 20.25L20.25 22.3477L15 17.0977L9.75 22.3477L7.65234 20.25L12.9023 15L7.65234 9.75L9.75 7.65234L15 12.9023L20.25 7.65234L22.3477 9.75L17.0977 15L22.3477 20.25Z",
                                  fill: "#C20D16"
                                }),
                            mask: "url(#mask0)"
                          })
                    ],
                    className: notificationStyle,
                    height: "30",
                    width: "30",
                    viewBox: "0 0 30 30",
                    xmlns: "http://www.w3.org/2000/svg"
                  });
    case /* NotificationInfo */1 :
        return JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("path", {
                          clipRule: "evenodd",
                          d: "M15 30C18.9781 30 22.7931 28.4192 25.6066 25.6066C28.4191 22.7928 30 18.9781 30 15C30 11.0219 28.4192 7.20687 25.6066 4.39344C22.7928 1.58094 18.9781 0 15 0C11.0219 0 7.20687 1.58081 4.39344 4.39344C1.58094 7.20716 0 11.0219 0 15C0 18.9781 1.58081 22.7931 4.39344 25.6066C7.20716 28.4191 11.0219 30 15 30ZM15 6.5625C15.7581 6.5625 16.4417 7.01903 16.7322 7.71972C17.0227 8.42041 16.8616 9.22728 16.3257 9.76319C15.7898 10.2991 14.9829 10.4602 14.2822 10.1697C13.5815 9.87916 13.125 9.19556 13.125 8.4375C13.125 7.40234 13.9648 6.5625 15 6.5625ZM13.125 13.125L16.875 13.125V23.4375H13.125L13.125 13.125Z",
                          fill: "#2E5299",
                          fillRule: "evenodd"
                        }),
                    className: notificationStyle,
                    height: "30",
                    width: "30",
                    viewBox: "0 0 30 30",
                    xmlns: "http://www.w3.org/2000/svg"
                  });
    case /* NotificationSuccess */2 :
        return JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("path", {
                          clipRule: "evenodd",
                          d: "M15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0ZM20.7973 9L12.8003 16.9949L9.10428 13.6131L7 15.9122L12.8957 21.3069L23 11.2027L20.7973 9Z",
                          fill: "#25AE88",
                          fillRule: "evenodd"
                        }),
                    className: notificationStyle,
                    height: "30",
                    width: "30",
                    viewBox: "0 0 30 30",
                    xmlns: "http://www.w3.org/2000/svg"
                  });
    case /* NotificationWarning */3 :
        return JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsx("path", {
                          clipRule: "evenodd",
                          d: "M15 0C11.0219 0 7.20688 1.58081 4.39344 4.39344C1.58094 7.20716 0 11.0219 0 15C0 18.9781 1.58081 22.7931 4.39344 25.6066C7.20716 28.4191 11.0219 30 15 30C18.9781 30 22.7931 28.4192 25.6066 25.6066C28.4191 22.7928 30 18.9781 30 15C30 11.0219 28.4192 7.20687 25.6066 4.39344C22.7928 1.58094 18.9781 0 15 0ZM15 23.4375C14.2419 23.4375 13.5583 22.981 13.2678 22.2803C12.9773 21.5796 13.1384 20.7727 13.6743 20.2368C14.2102 19.7009 15.0171 19.5398 15.7178 19.8303C16.4185 20.1208 16.875 20.8044 16.875 21.5625C16.875 22.5977 16.0352 23.4375 15 23.4375ZM16.875 16.875H13.125V6.5625H16.875V16.875Z",
                          fill: "#DC5800",
                          fillRule: "evenodd"
                        }),
                    className: Cn.make([
                          notificationStyle,
                          "text-teal-500"
                        ]),
                    height: "30",
                    width: "30",
                    viewBox: "0 0 30 30",
                    xmlns: "http://www.w3.org/2000/svg"
                  });
    
  }
}

var innerContainer = "flex";

var titleStyle = "font-bold text-lg text-dark-gray pb-1";

var descriptionStyle = "text-base text-dark-gray";

export {
  container ,
  innerContainer ,
  notificationStyle ,
  titleStyle ,
  descriptionStyle ,
  rootContainer ,
  icon ,
}
/* react/jsx-runtime Not a pure module */
