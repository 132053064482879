// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../common/util.bs.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import ReactMarkdown from "react-markdown";
import * as JsxRuntime from "react/jsx-runtime";
import * as NotificationStyles from "./NotificationStyles.bs.js";

function $$Notification(props) {
  var notificationType = props.notificationType;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: NotificationStyles.icon(notificationType)
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              Belt_Option.mapWithDefault(props.title, null, (function (title) {
                                      return JsxRuntime.jsx("p", {
                                                  children: Util.ReactStuff.s(title),
                                                  className: NotificationStyles.titleStyle
                                                });
                                    })),
                              JsxRuntime.jsx("p", {
                                    children: Util.ReactStuff.s(props.description),
                                    className: NotificationStyles.descriptionStyle
                                  }),
                              Belt_Option.mapWithDefault(props.markdown, null, (function (m) {
                                      return JsxRuntime.jsx(ReactMarkdown, {
                                                  children: Caml_option.some(Util.ReactStuff.s(m))
                                                });
                                    }))
                            ]
                          })
                    ],
                    className: NotificationStyles.innerContainer
                  }),
              className: Cn.make([
                    NotificationStyles.rootContainer(notificationType),
                    Belt_Option.getWithDefault(props.className, "")
                  ]),
              role: "alert"
            });
}

var make = $$Notification;

export {
  make ,
}
/* Util Not a pure module */
