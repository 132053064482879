// Generated by ReScript, PLEASE EDIT WITH CARE


var ssr_page_cache = "public, max-age=600, stale-while-revalidate";

var product_ssr_page_cache = "public, max-age=60, stale-while-revalidate";

var no_cache = "private, no-cache, no-store, max-age=0, must-revalidate";

export {
  ssr_page_cache ,
  product_ssr_page_cache ,
  no_cache ,
}
/* No side effect */
