// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Util from "../common/util.bs.js";
import * as CmsData from "../api/graphql/CmsData.bs.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Js_null from "@rescript/std/lib/es6/js_null.js";
import * as Constants from "../common/Constants.bs.js";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Js_promise from "@rescript/std/lib/es6/js_promise.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as ModifyBlocks from "../api/queries/modifyBlocks";

function modifyBlocks(prim0, prim1, prim2) {
  return ModifyBlocks.modifyBlocks(prim0, prim1, prim2);
}

function resolver(ctx, url, param) {
  if (Belt_Option.isNone(Js_dict.get(ctx.query, "preview"))) {
    ctx.res.setHeader("Cache-Control", Constants.ssr_page_cache);
  }
  var isPreview = Belt_Option.isSome(Js_dict.get(ctx.query, "preview"));
  var i = Js_dict.get(ctx.query, "lang");
  var language = i !== undefined ? i : null;
  var path = Belt_Array.get(Js_string.split("?", ctx.resolvedUrl), 0);
  var queryUrl = url !== undefined ? url : (
      path !== undefined ? path : ""
    );
  return Js_promise.then_((function (res) {
                var content = Belt_Option.flatMap(res, (function (p) {
                        return p.content;
                      }));
                var result = Js_null.fromOption(JSON.stringify(content));
                return Js_promise.then_((function (modifiedResult) {
                              return Promise.resolve({
                                          props: {
                                            content: modifiedResult
                                          }
                                        });
                            }), ModifyBlocks.modifyBlocks(result, isPreview, Util.EnvUtil.getCmsLanguage(language)));
              }), CmsData.getPageContent(Util.EnvUtil.getCmsLanguage(language), isPreview, queryUrl));
}

function parse(data) {
  return Belt_Option.map((data == null) ? undefined : Caml_option.some(data), Json.parseOrRaise);
}

export {
  modifyBlocks ,
  resolver ,
  parse ,
}
/* Util Not a pure module */
