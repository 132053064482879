// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BsCss from "../../../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Theme from "../../../css/Theme.bs.js";
import * as Theme__Responsive$PrezzeeRibbon from "@prezzee/ribbon/src/theme/Theme__Responsive.bs.js";

var title = Curry._1(BsCss.style, {
      hd: BsCss.fontSize(BsCss.rem(1.875)),
      tl: {
        hd: BsCss.fontWeight("bold"),
        tl: {
          hd: BsCss.color(Theme.Color.gray800),
          tl: {
            hd: BsCss.fontSize(BsCss.px(24)),
            tl: {
              hd: BsCss.textAlign("center"),
              tl: {
                hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.desktop, {
                      hd: BsCss.textAlign("left"),
                      tl: /* [] */0
                    }),
                tl: {
                  hd: BsCss.media(Theme__Responsive$PrezzeeRibbon.tabletSmUp, {
                        hd: BsCss.fontSize(BsCss.rem(2.5)),
                        tl: /* [] */0
                      }),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var formInput_ = Curry._1(BsCss.style, {
      hd: BsCss.width(BsCss.pct(100)),
      tl: {
        hd: BsCss.textAlign("left"),
        tl: {
          hd: BsCss.margin2(BsCss.px(6), BsCss.zero),
          tl: /* [] */0
        }
      }
    });

var errorTextStyle = Curry._1(BsCss.style, {
      hd: BsCss.display("block"),
      tl: {
        hd: BsCss.textAlign("left"),
        tl: {
          hd: BsCss.fontSize(BsCss.rem(0.75)),
          tl: {
            hd: BsCss.marginBottom(BsCss.rem(0.5)),
            tl: {
              hd: BsCss.marginLeft(BsCss.px(-2)),
              tl: {
                hd: BsCss.color(Theme.Color.prezzeeRed),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var forgotBlock = Curry._1(BsCss.style, {
      hd: BsCss.display("flex"),
      tl: {
        hd: BsCss.flexDirection("column"),
        tl: {
          hd: BsCss.justifyContent("center"),
          tl: {
            hd: BsCss.alignItems("center"),
            tl: /* [] */0
          }
        }
      }
    });

var linkStyle = Curry._1(BsCss.style, {
      hd: BsCss.display("block"),
      tl: {
        hd: BsCss.textDecoration("underline"),
        tl: {
          hd: BsCss.color(Theme.Color.blue400),
          tl: {
            hd: BsCss.focus({
                  hd: BsCss.color(Theme.Color.blue600),
                  tl: /* [] */0
                }),
            tl: {
              hd: BsCss.hover({
                    hd: BsCss.color(Theme.Color.blue600),
                    tl: /* [] */0
                  }),
              tl: {
                hd: BsCss.paddingTop(BsCss.px(15)),
                tl: /* [] */0
              }
            }
          }
        }
      }
    });

var subtitle = "sm:text-xl text-mid-gray text-center lg:text-left pb-8";

var accountMsg = "w-full mb-4";

var loginContainer = "w-full max-w-md mt-24 mx-auto px-4";

var textStyle = "block text-gray-800 text-sm font-bold mb-2 text-left mt-5";

var loginBtn = "mx-auto w-32 h-12 mt-6";

export {
  title ,
  subtitle ,
  accountMsg ,
  formInput_ ,
  loginContainer ,
  textStyle ,
  errorTextStyle ,
  loginBtn ,
  forgotBlock ,
  linkStyle ,
}
/* title Not a pure module */
