// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LoginComponent from "./LoginComponent.bs.js";
import * as OnboardingLayout from "../../../layouts/OnboardingLayout.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LoginOnboardingStyles from "./LoginOnboardingStyles.bs.js";
import * as OnboardingLayoutCreateAccountButton from "../../library/OnboardingLayoutCreateAccountButton.bs.js";

function LoginOnboarding(props) {
  return JsxRuntime.jsxs(OnboardingLayout.make, {
              children: [
                JsxRuntime.jsx(OnboardingLayoutCreateAccountButton.make, {}),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(LoginComponent.make, {}),
                      className: LoginOnboardingStyles.loginContainer
                    })
              ],
              onboardingKind: /* ShowAppDownload */1
            });
}

var Link;

var make = LoginOnboarding;

var $$default = LoginOnboarding;

export {
  Link ,
  make ,
  $$default ,
  $$default as default,
}
/* LoginComponent Not a pure module */
