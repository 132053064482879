import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { masquerade, getCustomerData } from 'api/queries/index';
import { saveTokenCookie } from 'auth/user/AuthUtils.bs';
import logger from 'utils/logger';

const Masquerade = () => {
  const router = useRouter();
  const masqUser = router.query?.masq_user as string;
  const masqEndpoint = router.query?.masq_endpoint as string;

  useEffect(() => {
    if (masqUser && masqEndpoint) {
      masquerade(masqEndpoint, masqUser)
        .then(data => {
          getCustomerData({
            Authorization: `Masq ${data.token}`,
          }).then(customer => {
            saveTokenCookie({
              userDetails: {
                uid: customer?.uid || null,
                firstName: customer?.firstName,
                lastName: customer?.lastName,
                phoneNumber: customer?.phone,
                email: customer?.email,
              },
              token: data.token,
              masquerade: true,
            });

            window.location.replace('/wallet/');
          });
        })
        .catch(err => {
          logger.error(
            {
              err: err,
              source: 'Masquerade API',
              journey: 'Masquerade',
            },
            `error: failed to masquerade user ${masqUser}`
          );
        });
    }
  }, []);

  return null;
};

export default Masquerade;
