// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as Util from "../../common/util.bs.js";
import * as Button from "./Button.bs.js";
import Link from "next/link";
import * as JsxRuntime from "react/jsx-runtime";

function OnboardingLayoutCreateAccountButton(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: Util.ReactStuff.s("Not registered?"),
                      className: "text-light-gray text-sm mr-6"
                    }),
                JsxRuntime.jsx(Link, {
                      href: "/register",
                      prefetch: false,
                      children: JsxRuntime.jsx("button", {
                            children: Util.ReactStuff.s("Create an account"),
                            "aria-label": "Register",
                            className: Cn.make([
                                  "w-40 h-12 ml-1",
                                  Button.primaryOutlineStyle
                                ]),
                            type: "button"
                          })
                    })
              ],
              className: "flex items-center float-right mr-4 lg:mr-10"
            });
}

var Link$1;

var make = OnboardingLayoutCreateAccountButton;

export {
  Link$1 as Link,
  make ,
}
/* Util Not a pure module */
