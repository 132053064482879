// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../../common/util.bs.js";
import * as NextSeo from "next-seo";
import Head from "next/head";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Router from "next/router";
import * as JsxRuntime from "react/jsx-runtime";

function SeoMeta(props) {
  var meta = props.meta;
  var router = Router.useRouter();
  var match = meta.seoMetaOgType.length;
  var ogType = match !== 0 ? meta.seoMetaOgType : "website";
  var ogImageAssetUrl = Belt_Option.mapWithDefault(meta.seoMetaOgImageAsset, "", (function (b) {
          return b.url;
        }));
  var twImageAssetUrl = Belt_Option.map(meta.seoMetaTwImageAsset, (function (b) {
          return b.url;
        }));
  var twImageUrl = twImageAssetUrl !== undefined ? twImageAssetUrl : meta.seoMetaTwImageAssetUrl;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(NextSeo.NextSeo, {
                      title: meta.seoMetaBasicTitle,
                      description: meta.seoMetaBasicDescription,
                      canonical: Util.SEOUtil.getCanonicalUrl(router.asPath),
                      languageAlternates: Util.SEOUtil.getLanguageAlternates(router.asPath),
                      openGraph: {
                        title: meta.seoMetaOgTitle,
                        description: meta.seoMetaOgDescription,
                        url: meta.seoMetaOgUrl
                      }
                    }),
                JsxRuntime.jsxs(Head, {
                      children: [
                        JsxRuntime.jsx("meta", {
                              content: ogImageAssetUrl,
                              property: "og:image"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: ogType,
                              property: "og:type"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: meta.seoMetaTwTitle,
                              property: "twitter:title"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: meta.seoMetaTwDescription,
                              property: "twitter:description"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: "summary_large_image",
                              property: "twitter:card"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: router.pathname,
                              property: "twitter:url"
                            }),
                        JsxRuntime.jsx("meta", {
                              content: twImageUrl,
                              property: "twitter:image"
                            })
                      ]
                    })
              ]
            });
}

var Head$1;

var make = SeoMeta;

export {
  Head$1 as Head,
  make ,
}
/* Util Not a pure module */
