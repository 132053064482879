// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Util from "../common/util.bs.js";
import * as React from "react";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as StaticImage from "../components/StaticImage.bs.js";
import * as DownloadAppleApp from "../components/library/DownloadAppleApp.bs.js";
import * as DownloadGoogleApp from "../components/library/DownloadGoogleApp.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var features = [
  "Keep all my eGift cards in one place",
  "Fast access from my phone while I shop",
  "Secure digital wallet",
  "Get offers",
  "Send gift cards even faster"
];

var make = React.memo(function (props) {
      var onboardingKind = props.onboardingKind;
      var match = Util.EnvUtil.currentCountry;
      var deviseImg;
      deviseImg = match.TAG === /* Ok */0 && match._0 === 3 ? ({
            width: 448,
            height: 265,
            src: "/images/laptop_wallet.png",
            alt: "Prezzee wallet"
          }) : ({
            width: 448,
            height: 275,
            src: "/images/prezzee_desktop_wallet.png",
            alt: "Prezzee wallet"
          });
      return JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("div", {
                          children: typeof onboardingKind === "number" ? (
                              onboardingKind !== 0 ? JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsxs("h2", {
                                                      children: [
                                                        Util.ReactStuff.s("Never forget a"),
                                                        JsxRuntime.jsx("br", {}),
                                                        Util.ReactStuff.s("gift card again.")
                                                      ],
                                                      className: "font-semibold text-3xl text-white mb-4 "
                                                    }),
                                                JsxRuntime.jsxs("p", {
                                                      children: [
                                                        JsxRuntime.jsx("strong", {
                                                              children: Util.ReactStuff.s("Buy, send and store ")
                                                            }),
                                                        Util.ReactStuff.s("gift cards, all"),
                                                        JsxRuntime.jsx("br", {}),
                                                        Util.ReactStuff.s("with a few clicks in the Prezzee App."),
                                                        JsxRuntime.jsx("br", {}),
                                                        JsxRuntime.jsx("strong", {
                                                              children: Util.ReactStuff.s("Download for free now.")
                                                            })
                                                      ],
                                                      className: "text-lg text-white font-thin leading-relaxed mb-8"
                                                    }),
                                                JsxRuntime.jsxs("div", {
                                                      children: [
                                                        JsxRuntime.jsx(DownloadGoogleApp.make, {
                                                              className: "mr-3 w-32"
                                                            }),
                                                        JsxRuntime.jsx(DownloadAppleApp.make, {
                                                              className: "ml-3 w-32"
                                                            })
                                                      ],
                                                      className: "flex"
                                                    })
                                              ],
                                              className: ""
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(StaticImage.make, {
                                                    src: "/images/prezzee_mobile_app.png",
                                                    alt: "Prezzee iOS and Android mobile applications",
                                                    width: 448,
                                                    height: 457,
                                                    className: "max-w-md w-full h-auto py-6 my-auto"
                                                  }),
                                              className: "flex-grow flex"
                                            })
                                      ],
                                      className: "px-2 xl:px-10 xxl:px-16 flex flex-col flex-grow"
                                    }) : JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("ul", {
                                              children: Util.ReactStuff.ate(Belt_Array.mapWithIndex(features, (function (index, feature) {
                                                          return JsxRuntime.jsxs("li", {
                                                                      children: [
                                                                        JsxRuntime.jsx("div", {
                                                                              children: JsxRuntime.jsx(StaticImage.make, {
                                                                                    src: "/images/icon_tick.svg",
                                                                                    alt: feature,
                                                                                    width: 20,
                                                                                    height: 20
                                                                                  }),
                                                                              className: "mt-1 mr-4"
                                                                            }),
                                                                        JsxRuntime.jsx("span", {
                                                                              children: Util.ReactStuff.s(feature),
                                                                              className: "block w-full max-w-xs"
                                                                            })
                                                                      ],
                                                                      className: "flex mb-8"
                                                                    }, "index-" + String(index));
                                                        }))),
                                              className: "flex-grow text-white text-xl"
                                            }),
                                        JsxRuntime.jsx(StaticImage.make, {
                                              src: deviseImg.src,
                                              alt: deviseImg.alt,
                                              width: deviseImg.width,
                                              height: deviseImg.height,
                                              className: "max-w-md w-full h-auto py-6 mt-auto"
                                            })
                                      ],
                                      className: "px-2 xl:px-10 xxl:px-16 flex flex-col flex-grow"
                                    })
                            ) : JsxRuntime.jsxs("div", {
                                  children: [
                                    Belt_Array.mapWithIndex(onboardingKind._0, (function (i, text) {
                                            return JsxRuntime.jsx("h2", {
                                                        children: Util.ReactStuff.s(text),
                                                        className: "font-bold text-4xl text-white text-center"
                                                      }, "title" + String(i));
                                          })),
                                    JsxRuntime.jsx(StaticImage.make, {
                                          src: "/images/presents.svg",
                                          alt: "Presents",
                                          width: 518,
                                          height: 303,
                                          className: "w-full pt-20 flex-grow"
                                        })
                                  ]
                                }),
                          className: "hidden w-full lg:flex flex-col lg:w-1/3 py-20 pb-5 px-5 bg-prezzee-red"
                        }),
                    JsxRuntime.jsx("div", {
                          children: props.children,
                          className: "w-full lg:w-2/3 bg-white text-center py-5"
                        })
                  ],
                  className: "flex flex-wrap-reverse w-full"
                });
    });

export {
  features ,
  make ,
}
/* make Not a pure module */
